import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './DeleteUser.css';

const DeleteUser = () => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);

    const fetchUsers = async () => {
        try {
            console.log('Fetching users...');
            const response = await axios.get('http://localhost:5000/api/users');
            console.log('Users fetched:', response.data);
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching users', error);
        }
    };

    const deleteUser = async (id) => {
        try {
            console.log(`Deleting user with id: ${id}`);
            await axios.delete(`http://localhost:5000/api/users/${id}`);
            console.log('User deleted');
            fetchUsers(); // Refresh the list after deletion
        } catch (error) {
            console.error('Error deleting user', error);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const openProfile = (user) => {
        setSelectedUser(user);
    };

    const closeProfile = () => {
        setSelectedUser(null);
    };

    return (
        <div className="container">
            <h1>User List</h1>
            {users.length === 0 ? (
                <p>No users found.</p>
            ) : (
                <table className="user-table">
                    <thead>
                        <tr>
                            <th>Full Name</th>
                            <th>Email</th>
                            <th>Profile</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(user => (
                            <tr key={user._id}>
                                <td>{user.fullName}</td>
                                <td>{user.email}</td>
                                <td>
                                    <button className="profile-button" onClick={() => openProfile(user)}>Profile</button>
                                </td>
                                <td>
                                    <button className="delete-button" onClick={() => deleteUser(user._id)}>Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            {selectedUser && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close-button" onClick={closeProfile}>&times;</span>
                        <h2>User Profile</h2>
                        <p><strong>Full Name:</strong> {selectedUser.fullName}</p>
                        <p><strong>Email:</strong> {selectedUser.email}</p>
                        <p><strong>Address:</strong> {selectedUser.address}</p>
                        <p><strong>CIN:</strong> {selectedUser.cin}</p>
                        <p><strong>Password:</strong> {selectedUser.password}</p>
                        <p><strong>Start Date:</strong> {new Date(selectedUser.startDate).toLocaleDateString()}</p>
                        <p><strong>National ID:</strong> <a href={`http://localhost:5000/${selectedUser.nationalID}`} target="_blank" rel="noopener noreferrer">View Document</a></p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DeleteUser;
