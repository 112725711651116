import React from "react";
import AboutBackground from "../Assets/about-background.png";
import AboutBackgroundImage from "../Assets/about-background-image2.png";
import aboutimg from "../Assets/about.png"
import { BsFillPlayCircleFill } from "react-icons/bs";


const About = () => {
  return (
    <div id="about" className="about-section-container">
      <div className="about-background-image-container">
        <img src={AboutBackground} alt=""  className="background-image"   />
      </div>
      <div className="about-section-image-container">
        <img src={aboutimg} alt=""  className="about-image"/>
      </div>
      <div className="about-section-text-container">
        <h1 className="primary-heading">
        <pre> Marokko Biz of 31.01.12 <br/>
        <p >S.A.R.L </p>
            </pre>
         Crafting Dreams.
        </h1>
        <p className="primary-text">
        
        Welcome to Marokko Biz of 31.01.12 S.A.R.L, your trusted partner.<br/><br/> 
        
        Marokko Biz of 31.01.12 S.A.R.L, is the main company behind a network of sister companies specializing in various sectors, among other real estate, agriculture, and investment.<br/><br/> 
        
        Our head office is strategically located in Tangier, and a satellite office in Kenitra, Morocco.<br/><br/> 
        Marokko Biz of 31.01.12 S.A.R.L's primary focus is on holding shares in other companies, managing a diverse property portfolio, and offering investment opportunities to co-investors.
    
        </p>
        <p className="primary-text">
        Let us help you find your place in the world.
        </p>
      
      </div>
    </div>
  );
};

export default About;
