import React, { useState } from 'react';
import axios from 'axios';
import './AddUser.css'; // Import the CSS file

const AddUser = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    address: '',
    cin: '',
    password: '',
    email: '',
    startDate: '',
    nationalID: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      nationalID: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    Object.keys(formData).forEach((key) => {
      data.append(key, formData[key]);
    });

    try {
      await axios.post('http://localhost:5000/api/users', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      alert('User added successfully!');
      // Reset the form
      setFormData({
        fullName: '',
        address: '',
        cin: '',
        password: '',
        email: '',
        startDate: '',
        nationalID: null,
      });
    } catch (error) {
      console.error('Error adding user:', error);
      alert('Error adding user.');
    }
  };

  return (
    <form className="add-user-form" onSubmit={handleSubmit}>
      <h2>Add New User</h2>
      <div className="form-group">
        <label>Full Name:</label>
        <input
          type="text"
          name="fullName"
          value={formData.fullName}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label>Address:</label>
        <input
          type="text"
          name="address"
          value={formData.address}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label>CIN:</label>
        <input
          type="text"
          name="cin"
          value={formData.cin}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label>Password:</label>
        <input
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label>Email:</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label>Start Date:</label>
        <input
          type="date"
          name="startDate"
          value={formData.startDate}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label>Upload National ID:</label>
        <input
          type="file"
          name="nationalID"
          onChange={handleFileChange}
          required
        />
      </div>
      <button type="submit" className="save-button">Save</button>
    </form>
 
  );
};

export default AddUser;
