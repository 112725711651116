import React,  { useState } from "react";
import estate from "../Assets/estate2.jpg";
import tractor from "../Assets/tractor.jpg";
import timer from "../Assets/timer.png"
import dev from "../Assets/dev1.jpg"
import Cowork from "../Assets/Cowork2.jpg"
import rh from "../Assets/rh.jpg"
import InvestmentConsultationIMG from "../Assets/InvestmentConsultationIMG.jpg";


const Work = () => {



  const networkData = [
    {
        image: tractor,
        title: "Marokko Biz Farming S.A.R.L",
        text: "Our sister company Marokko Biz of 31.01.12 Farming S.A.R.L focus area is import of agricultural products and machines. Companies also own agricultural land .",
        url: "#"
    },
    {
        image: estate,
        title: "Marokko Biz Real Estate S.A.R.L",
        text: "Marokko Biz Real Estate S.A.R.L is a company responsible for all lettings of land, buildings, and apartments. Everything that is rented out belongs to one of the companies associated with Marokko Biz of 31.01.12 S.A.R.L",
        url: "#"
    },
    {
      image: dev,  
      title: "Marokko Biz IT Development S.A.R.L",
        text: "Marokko Biz IT Development S.A.R.L is an IT company that handles both internal and external tasks.",
        url: "."
    },


    
    {
      image: Cowork,  
      title: "Marokko Biz CoWorking",
        text: "Marokko Biz CoWorking is what you can call a discount CoWorking. prices start from MAD 50.00 per month",
        url: "https://marokkobizcoworking.com/"
    },
    {
      image: rh,  
      title: "Marokko Biz Recruitment Agency S.A.R.L",
        text: "Marokko Biz Recruitment is a company that provides HR services related to the employment of personnel. The company also rents labor on an hourly or monthly basis",
        url: "#"
    },
    {
      image: InvestmentConsultationIMG,
      title: "Marokko Biz Investment S.A:R.L",
      text: "  Become a co-investor with Marokko Biz of 31.01.12 S.A.R.L and enjoy guaranteed returns on your investment over a 10-year period.",
      url: "#"
    },
];






  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handleFirstLinkClick = (e) => {
    e.preventDefault();
    setIsPopupVisible(true);
  };

  const closePopup = () => {
    setIsPopupVisible(false);
  };
;


  return (
    <div className="work-section-wrapper">
      <div  className="work-section-top">

      
        <pre class="custom-heading" > Discover our Network of Affiliated Companies
   </pre>
   

      </div>

      <div className="work-section-bottom">
        {networkData.map((data,index) => (
          <div className="work-section-info" key={data.title}>
            <div className="info-boxes-img-container">
              <img src={data.image} alt="" />
            </div>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
            {index === 0 || index === 1 || index === 4 || index === 5 ? (
 <button onClick={handleFirstLinkClick}>See More</button>          ) : (
            <a href={data.url} target="_blank" rel="noopener noreferrer">See More</a>
          )}
          </div>
        ))}
      </div>

      {isPopupVisible && (
        <div className="popup-overlay">
          <div className="popup-content">
            <span className="close-btn" onClick={closePopup}>&times;</span>
            <img src={timer} alt="Coming Soon" className="timer" />
            <h1>It's coming soon</h1>
          </div>
        </div>
      )}





    </div>
  );
};

export default Work;
