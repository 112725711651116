import React from "react";
import Logo from "../Assets/Logo.png";

import { FaFacebookF } from "react-icons/fa";



//  images for the team members
import KlausImage from "../Assets/home.jpg";
import MahaImage from "../Assets/femme.jpg";
import KhalidImage from "../Assets/home.jpg";
import AsmaeImage2 from "../Assets/femme.jpg";
import AsmaeImage from "../Assets/femme.jpg";

const teamMembers = [
  { name: "Klaus Kristoffersen", role: "CEO", img: KlausImage },
  { name: "Maha", role: "Office assistant", img: MahaImage },
  { name: "Khalid", role: "Intern Sale", img: KhalidImage },
  { name: "Asmae", role: "Intern Money transfer", img: AsmaeImage2 },
  { name: "Asmae", role: "IT Developer MA", img: AsmaeImage }
 

];



const Footer = () => {
  return (
    <div className="footer-wrapper">
            <div className="footer-section-one">
                              <div className="footer-logo-container">
                                <img src={Logo} alt="" />
                              </div>
                                <div className="footer-icons">
                                      
                                      <a href="https://web.facebook.com/marokkobizsarl?_rdc=1&_rdr" target="_blank"><FaFacebookF /></a> 
                                </div>  
              
                                <div className="footer-section-columns">

                                      <h4><span>@Marokko Biz of 31.01.12 SARL</span></h4> 
                                </div>
            </div>


            <div className="team-section">
        <div className="team">
          {teamMembers.map(member => (
            <div key={member.name} className="team-member">
              <img src={member.img} alt={member.name} />
              <h3>{member.name}</h3>
              <p>{member.role}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="footer-section-two">
        <div className="footer-section-columns">
          <br /><br /><br /><br /><br /><br />
          <span>Terms & Conditions</span>
          <span>Privacy Policy</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;