import React from "react";
import BannerBackground from "../Assets/home-banner-background.png";
import BannerImage from "../Assets/home-banner-image.png";
import Navbar from "./Navbar";
const Home = () => {
  return (
    <div className="home-container">
 <Navbar />

      <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src={BannerBackground} alt="" />
        </div>
        <div className="home-text-section">
          
          <h1 className="primary-heading" >
            <pre> Marokko Biz of 31.01.12<br/>
             <p >S.A.R.L </p>
            </pre>

          </h1>
          <p className="primary-text">
          Welcome to Marokko Biz of 31.01.12 S.A.R.L<br/>
          A trusted partner.  
          </p> 
        </div>
        <div className="home-image-section">
          <img src={BannerImage} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Home;
