// Login.js
import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';

function Login() {
  const [open, setOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setUsername('');
    setPassword('');
    setError('');
  };

  const handleLogin = () => {
    // Check credentials
    if (username === 'klaus' && password === 'klaus006') {
      // Successful login logic here
      console.log('Login successful');
      const popupWindow = window.open('', '_self', 'width=400,height=200');
      if (popupWindow) {
        popupWindow.document.write(`
          <html>
            <head>
              <title>Popup Window</title>
              <style>
                body { font-family: Arial, sans-serif; text-align: center; }
                button { margin: 10px; padding: 10px 20px; font-size: 16px; cursor: pointer; }
              </style>
            </head>
            <body>
              <h2>Welcome, ${username}!</h2>
              <button id="add-new-button">
              <a href="/AddUser">
              Add New
              </a>
              </button>

              <button id="add-new-button">
              <a href="/DeleteUser">
            Delete one
              </a>
              </button>

            </body>
          </html>
        `);

        
      } else {
        alert('Popup blocked! Please allow popups for this site.');
      }
      handleClose();
    } else {
      setError('Invalid username or password');
    }
  };

  return (
    <>
      <Button variant="text" onClick={handleClickOpen} style={{ color: '#000' }}>
        Login
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Login</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="username"
            label="Username"
            type="text"
            fullWidth
            variant="standard"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            margin="dense"
            id="password"
            label="Password"
            type="password"
            fullWidth
            variant="standard"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={error !== ''}
            helperText={error}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleLogin}>Login</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Login;
