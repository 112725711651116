import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Components/Home';
import About from './Components/About';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import Network from './Components/Network';
import Login from './Components/Login';
import AddUser from './Components/AddUser';
import Navbar from './Components/Navbar';
import DeleteUser from './Components/DeleteUser';
import Profile from './Components/Profile'; // Import Profile component

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<>
            <Home />
            <About />
            <Network />
            <Contact />
            <Footer />
          </>} />
          <Route path="/AddUser" element={<AddUser />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/DeleteUser" element={<DeleteUser />} />
          <Route path="/profile/:id" element={<Profile />} /> {/* Add Profile route */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
