// Components/Profile.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

function Profile() {
  const { id } = useParams();
  const [user, setUser] = useState(null);

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    try {
      const response = await axios.get(`http://localhost:5000/api/users/${id}`);
      setUser(response.data);
    } catch (error) {
      console.error('Error fetching user', error);
    }
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2>{user.fullName}</h2>
      <p>Email: {user.email}</p>
      <p>Address: {user.address}</p>
      <p>CIN: {user.cin}</p>
      <p>Start Date: {new Date(user.startDate).toLocaleDateString()}</p>
    </div>
  );
}

export default Profile;
